/* work-sans-regular - latin */
/*
https://google-webfonts-helper.herokuapp.com/fonts/work-sans?subsets=latin
*/
/* work-sans-300 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/work-sans-v9-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('fonts/work-sans-v9-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/work-sans-v9-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/work-sans-v9-latin-300.woff') format('woff'), /* Modern Browsers */
  url('fonts/work-sans-v9-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/work-sans-v9-latin-300.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-regular - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/work-sans-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('fonts/work-sans-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/work-sans-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/work-sans-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('fonts/work-sans-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/work-sans-v9-latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-500 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/work-sans-v9-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('fonts/work-sans-v9-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/work-sans-v9-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/work-sans-v9-latin-500.woff') format('woff'), /* Modern Browsers */
  url('fonts/work-sans-v9-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/work-sans-v9-latin-500.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-600 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/work-sans-v9-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('fonts/work-sans-v9-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/work-sans-v9-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/work-sans-v9-latin-600.woff') format('woff'), /* Modern Browsers */
  url('fonts/work-sans-v9-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/work-sans-v9-latin-600.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-700 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/work-sans-v9-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('fonts/work-sans-v9-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/work-sans-v9-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/work-sans-v9-latin-700.woff') format('woff'), /* Modern Browsers */
  url('fonts/work-sans-v9-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/work-sans-v9-latin-700.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/*-----------------------------------*/

html{
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
  -webkit-tap-highlight-color:transparent;
  user-select: none;
  font-family: "Work Sans";
}

body {
  list-style-position: outside;
  list-style-type:none;
  pointer-events: auto;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
