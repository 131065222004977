.App {
  display: grid;
  grid-template-columns: 24.65% 33.8% auto;
  height: 970px;
}

.App-additional{
  display: grid;
  grid-template-columns: 16.15% 650px auto;
  height: 970px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.My-keyboard-container{
  position: fixed;
  top: calc(100vh - 330px);
  width: 100%;
}

.Hidden{
  display: none;
}

.Additional-part{
  margin-left: 30%;
  margin-right: 30%;
}

.logo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  max-height: 185px;
  background-color: #324c6b;
  color: white;
}

.left-part {
  display: flex;
  align-items: center;
}

.left-part > *{
  margin-right: 20px;
}

.header-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70px;
}

.big-text {
  font-size: 40px;
  font-weight: 600;
  margin: 0px;
  line-height: 32px;
}

.right-part {
  display: flex;
  align-items: center;
}

.logo-header img {
  max-height: 70px;
}