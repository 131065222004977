.List-part-activities{
    width: 1083px;
}

.activity-head-element{
    color:#6c757d!important;
}

.additional-activity-head{
    background-color: #efefef;
    display: grid;
    grid-template-columns: 5% 15% 30% 30% 11% auto;
}

.additional-activity-height{
    height: 48px;
    display: grid;
    grid-template-columns: 5% 15% 30% 30% 11% auto;
}

.additional-activity-element{
    font-weight: 400;
}