.List-part{
    height: 100%;
}

.Scroll{
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 880px;
    margin-bottom: 16px;
}

.Scroll-additional-list{
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 885px;
}

.Person-location-head{
    display: grid;
    grid-template-columns: 40.3% 37.6% auto;
    padding-left: 24px;
    font-weight: bold;
    font-size: 1rem;
    font-family: Work Sans, serif;
    color:#6c757d !important;
    height: 48px;
    align-items: center;
}

.Person-location{
    display: grid;
    grid-template-columns: 40.3% 37.6% 11% auto;
    height: 69px;
    align-items: center;
    font-weight: 500;
    padding-left: 24px;
    font-size: 1rem;
    font-family: 'Work Sans';
    line-height: 1.5;
    color: #6c757d!important;
    background-color: rgba(0, 0, 0, 0.05);
}

.Column-element{
    padding-left: 12px;
    font-weight: 500;
}



.Search-resident-element{
    padding: 12px;
}

.head-element{
    font-weight: 700;
}

.Header{
    padding-top: 24px;
    padding-left: 24px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 2.5rem;
    color:#032d51;
    line-height: 1.2;
    font-family: "Work Sans";
}

.List-circle {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    display: inline-block;
    justify-content: center;
    background-image: url("../../Images/AL_WayIkon_blaa_2.svg");
    box-shadow: 0 0 4px silver;
    vertical-align: middle;
}

.Input{
    margin: 48px;
    padding: 8px 24px;
    border:1px solid #334C6B;
    box-shadow: 0 0 2px silver;
    width: 425px !important;
    font-family: "Work Sans";
    font-weight: 500;
    font-size: 16px;
    line-height: inherit;
}

.Search-resident-head{
    display: grid;
    grid-template-columns: auto 36.8%;
    padding-left: 5%;
    font-weight: 700;
    font-size: 1rem;
    font-family: "Work Sans";
    color: #334C6B !important
}

.All-resident{
    display: grid;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color:#6c757d !important;
    background-color: rgba(0,0,0,.05);
    font-family: "Work Sans";
    font-weight: 500;
    border-top: 2px solid #334C6B;
    padding: 12px 12px 12px 48px;
}

.All-resident-text {
    margin-bottom: 8px;
    height: 38px;
    display: flex;
    align-items: center;
}

.Residents{
    display: grid;
    grid-template-columns: auto 15.6% 20.8%;
}

.No-result{
    display: grid;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-weight: 500;
    font-size: 1rem;
    font-family: "Work Sans";
    color: #6c757d !important;
    background-color: rgba(0,0,0,.05);
}
/*without data*/
/*grid-template-columns: 3.9% 20.1% 26.5% 25.6% 11% auto;*/

/*with data*/
/*3.9% 20.8% 27.4% 26% 11% auto*/
.Activity-head{
    display: grid;
    grid-template-columns: 3.9% 20.8% 27.4% 26% 11% auto;
    font-weight: bold;
    font-size: 1rem;
    font-family: Work Sans, serif;
    color:#334C6B !important;
    height: 48px;
    align-items: center;
}

.Activity{
    display: grid;
    grid-template-columns: 3.9% 20.8% 27.4% 26% 11% auto;
    height: 69px;
    align-items: center;
    font-weight: bold;
    font-size: 1rem;
    font-family: "Work Sans",serif;
    color: #6c757d!important;
    background-color: rgba(0, 0, 0, 0.05);
}
