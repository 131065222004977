.list-3{
    display: grid;
    grid-template-columns: 50% 50%;
}

.additional-list-element{
    display: grid;
    grid-template-columns: 5% 82% auto;
    color: #606060;
    font-size: 24px;
    font-family: 'Work Sans';
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 400;
}

.header{
    color: #606060;
    font-size: 30px;
    font-family: 'Work Sans';
    font-weight: bold;
    padding-left: 5%;
}

.header-4{
    display: grid;
    grid-template-columns: 33% 34% 33%;
}

.list{
    display: grid;
    grid-template-columns: 33% 34% 33%;
}

.list-4-10{
    display: grid;
    grid-template-columns: 50% 50%;
}