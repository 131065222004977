.Keyboard-style .hg-button {
    height: 65px;
    width: 6%;
    max-width: 90px;
    margin: 3px;
    border-radius: 4px;
    border: none;
    background: #eee;
    color: #222;
    font-size: 1.05rem;
    outline: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    padding: 0;
    position: relative;
    box-shadow: none;
}

.hg-button:focus {
    background-color: orange;
}

.Keyboard-style{
    background-color: white;
    padding-bottom: 5px;
}

.Keyboard-style .hg-standardBtn{
    height: 65px;
    background-color: #EEEEEE;
}

.Keyboard-style .hg-button-lock{
    height: 65px;
    background-color: #EEEEEE;
    background-image: url("../../Images/Keyboard/caps-lock-button.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
}

.Keyboard-style .hg-button-enter{
    height: 65px;
    background-color: #EEEEEE;
    background-image: url("../../Images/Keyboard/tick-mark.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
}

.Keyboard-style .hg-button-bksp{
    height: 65px;
    background-color: #EEEEEE;
    background-image: url("../../Images/Keyboard/left-arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;
}

.Keyboard-style .hg-button-space{
    height: 65px;
    width: 34%;
    max-width: 500px;
    flex-grow: unset;
    background-color: #EEEEEE;
}

.hg-button-space span {
    height: 24px;
}

.hg-button-backspace span {
    height: 24px;
}

.hg-button-caps span {
    height: 24px;
}

.Keyboard-style .hg-row {
    display: flex;
    justify-content: center;
    margin: 0 !important;
}

.hg-rows > div:first-child > div {
    height: 35px !important;
    max-width: 90px;
    margin: 3px;
    color: #222;
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn="@"] {
	max-width: 90px;
}

.hg-theme-default .hg-row .hg-button-container, .hg-theme-default .hg-row .hg-button:not(:last-child) {
	margin-right: 3px;
}
