.clock-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.clock-time p {
    font-size: 52px;
    font-weight: 600;
    line-height: 36px;
    margin: 0px;
}

.clock-time {
    display: flex;
    align-items: center;
    justify-content: end;
}

.clock-date {
    font-size: 12px;
}