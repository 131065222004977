.Menu {
    padding-top: 16px;
    display: grid;
    height: 871px;
}

.MenuContainer{
    height: 970px;
    box-shadow: -1px 0 6px 5px rgba(12, 57, 94, 0.54)!important;
    background-color: #f8f9fa !important;
    padding-right: 15px;
}

.Circle {
    height: 103px;
    width: 103px;
    border-radius: 50%;
    border: #334C6B 2px solid;
    background-color: white;
    box-shadow: 5px 5px 8px 2px rgb(200 200 200 / 20% );
    vertical-align: middle;
    line-height: 103px;
}

.Circle-clicked{
    height: 103px;
    width: 103px;
    border-radius: 50%;
    border: #334C6B 2px solid;
    background-color: #334C6B;
    box-shadow: 5px 5px 8px 2px rgb(200 200 200 / 20% );
}

.Circle-clicked img{
    filter: contrast(1000%) invert(100%);
    vertical-align: middle;
}

.Image-style{
    width: 100px;
    height: 100px;
    margin: 1.5px;
}

.Card-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1.1rem 1rem;
}

.Card-title{
    text-align: center;
    font-size: 19px;
    font-weight: 600;
    color:#334C6B;
    margin-top: 4px;
    font-family: 'Work Sans';
}