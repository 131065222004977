.Map-header{
    font-size: 40px;
    color:#032d51 !important;
    font-weight: bold;
    font-family: "Work Sans";
    line-height: 1.2;
    margin-bottom: 8px;
}

.Map{
    padding-left: 24px;
    padding-top: 24px;
    position: relative;
}

.Map-Bigger{
    padding: 24px;
    position: relative;
    width: 1570px;
}

.BottomMenu{
    height: calc(100vh - 865px);
}

.Height{
    max-height: 173px;
}

.Picture{
    position: relative;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.Screen-position{
    position: absolute;
    width: 20px;
    display: block;
    height: 20px;
    box-shadow: 0 0 3px grey;
    border-radius: 50%;
    background: #e82626;
    animation: beat 3s ease 0s infinite;
}

.Screen-position::after{
    content: 'Du er her';
    white-space: nowrap;
    text-shadow: 0 0 5px white;
    position: absolute;
    margin: 0 0 0 24px;
    color:#334C6B !important;
}

.Arrow {
    background: url("../../Images/Arrow.svg") no-repeat center center;
    width: 700px;
    height: 300px;
    margin: 0;
}

.Message {
    font-size: 100px;
    color: #1d4159;
}

@keyframes beat {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
}

.Pointer {
    background: url("../../Images/AL_WayIkon_hvid_2.svg") no-repeat center center;
    position: absolute;
    width: 120px;
    height: 120px;
    z-index: 1000;
    animation: spinHorizontal 3s infinite linear;
}

@keyframes spinHorizontal {
    0%{
        transform: rotateY(0deg)
    }
    100%{
        transform: rotateY(360deg);
    }
}